<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item label="会员昵称" name="nickname" class="ui-form__item">
						<a-input v-model:value="formState.nickname" placeholder="请输入会员昵称"></a-input>
					</a-form-item>
					
					<a-form-item label="会员手机号" name="phone" class="ui-form__item">
						<a-input v-model:value="formState.phone" placeholder="请输入会员手机号"></a-input>
					</a-form-item>
				</a-row>
				<a-row>
					<a-col :span="18"></a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			<a-row style="font-size: 16px;line-height: 30px;">
				当前推荐人：
				<a-image style="width: 30px;height: 30px;margin-right: 10px;border-radius: 5px;" v-if="newRecommendInfo.recommendAvatar" :src="newRecommendInfo.recommendAvatar"></a-image>
				{{ newRecommendInfo.recommendNickname }}
				<span style="margin-left: 10px;">{{ newRecommendInfo.recommendPhone }}</span>
			</a-row>

			<a-table style="margin-top: 10px;" rowKey="id" :dataSource="list" :columns="columns" :pagination="pagination">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'action'">
						<a-button v-permission="['member_distributor_list_user', 'member_manage_info_user']" type="link" :disabled="newRecommendId === record.id" @click="onBindRecommend(record)">修改推荐人为此人</a-button>
					</template>
				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import { getBaseUserList, bindRecommend } from '@/service/modules/distribution.js';
	export default {
		props: {
			id: {
				type: Number,
				default: 0
			},
			recommendId: {
				type: Number,
				default: 0
			},
			recommendInfo: {
				type: Object,
				default: ()=> {
					return {}
				}
			}
		},
		data() {
			return {
				loading: false,
				newRecommendId: 0,
				newRecommendInfo: {},
				formState: {},
				searchData: {},
				list: [],
				columns: [{
					title: '会员昵称',
					dataIndex: 'nickname'
				}, {
					title: '会员手机号',
					dataIndex: 'phone'
				}, {
					title:'操作',
					key: 'action'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				}
			}
		},
		created() {
			this.newRecommendId = this.recommendId;
			this.newRecommendInfo = this.recommendInfo;
			this.getData();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getBaseUserList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onBindRecommend(item) {
				this.$confirm({
					title: '提示',
					content: '确定要修改推荐人吗?',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await bindRecommend({
								userId: this.id,
								recommendId: item.id
							});
							this.loading = false;
							if (ret.code === 200) { 
								this.$message.success('修改推荐人成功');
								this.newRecommendId = item.id;
								this.newRecommendInfo = {
									recommendNickname: item.nickname || '',
									recommendAvatar: item.avatar || '',
									recommendPhone: item.phone || ''
								}
								this.$emit('onRef')
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
